import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import theme from '../theme'
import { Alert, Autocomplete, Box, Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, Snackbar } from '@mui/material';
import api from '../services/api';
import { render } from 'react-dom';
import { ConstructionOutlined } from '@mui/icons-material';




export default function FormDialog(props) {
  const [handlerSnack, setHandlerSnack] = React.useState()

  const item = {
    email: '',
    name: '',
    username: "",
    password: "",

  }

  const [state, setState] = React.useState(item);

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };


  React.useEffect(() => {
    if (!props.open) return
    getEditInfo()

  }, [ props.open])

  const getEditInfo = () => {
    setState(item)
    api.get(`user/me/`)
      .then(res => {
        console.log(res)
        setState(res.data)
      }).catch(err => setState(item))

  }
  const showNotify = (msg, sev = "success") => {
    setMsgSnack(msg)
    setSevSnack(sev)
    setHandlerSnack(true)
  }

  const [msgSnack, setMsgSnack] = React.useState('')
  const [sevSnack, setSevSnack] = React.useState('')
 

 

  const handleSubmit = (event) => {
    event.preventDefault()
    if(state.password !== state.repeat_password){
      showNotify("As senhas não conferem.", 'error')
      return
    }
    if(state.password != undefined){
      if (state.password.length < 8) {
        showNotify("A senha deve conter no mínimo 8 caracteres.", 'error')
        return
      }
    }
    const handlerClose = () => {
      props.handlerClose()
    }
   
      api.put('user/me/', state)
      .then((res) => {
        if (res?.data?.error) {
          showNotify(res.data.error, 'error')
          return
        }
          showNotify("Informações do perfil alteradas com sucesso.")
          handlerClose()
        }).catch((err) => {
          console.l
          showNotify("Um erro ocorreu...", 'error')
        })
    
  }

  return (
    <div>
      <Dialog open={props.open} fullWidth={true} scroll={'paper'}
        maxWidth='sm'>
      <Snack open={handlerSnack} message={msgSnack} severity={sevSnack} handlerSnack={() => setHandlerSnack(false)} />

        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <DialogTitle>
            Perfil
          </DialogTitle>
          <DialogContent>

            <TextField
            disabled
              size="small"
              required
              margin="dense"
              id="name"
              name="name"
              label="Nome"
              type="name"
              fullWidth
              value={state.name}
              onChange={handleChange}
              variant="outlined"
            />
            <TextField
              disabled
              size="small"
              margin="dense"
              id="username"
              name="username"
              label="Nome do usuário"
              type="username"
              fullWidth
              value={state.username}
              onChange={handleChange}
              variant="outlined"
            />
            <TextField
              size="small"
              margin="dense"
              id="email"
              name="email"
              label="email"
              type="email"
              fullWidth
              value={state.email}
              onChange={handleChange}
              variant="outlined"
            />
            {/* <TextField
              size="small"

              margin="dense"
              id="password"
              name="password"
              label="Senha"
              type="password"
              minLength="8"
              fullWidth
              value={state.password}
              onChange={handleChange}
              variant="outlined"
            /> */}
            <TextField
              size="small"

              margin="dense"
              id="repeat_password"
              name="repeat_password"
              label="Nova Senha"
              type="password"
              minLength="8"
              fullWidth
              value={state.repeat_password}
              onChange={handleChange}
              variant="outlined"
            />
            

          </DialogContent>
          <DialogActions>
            <Button sx={{ color: theme.palette.text.primary }} onClick={props.handlerClose}>Fechar</Button>
            <Button sx={{ color: theme.palette.text.primary }} type='submit'> Salvar</Button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
}

const Snack = (props) => {
  return (
    <Snackbar
      open={props.open}
      autoHideDuration={6000}
      onClose={() => { props.handlerSnack() }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <Alert onClose={() => { props.handlerSnack() }} severity={props.severity ? props.severity : 'error'} sx={{ width: '100%' }}>
        {props.message}
      </Alert>
    </Snackbar>
  )
}